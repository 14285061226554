/* You can add global styles to this file, and also import other style files */

/* Button Styles */

$warehouse-base-color: #fcd861;

.btn-dribble {
  background-color: #e49ef1 !important;
}

.btn-default {
  background-color: #b1b1b1 !important;
}

/* End of Button Styles */

.navbar.navbar-transparent {
  background-color: #fcd861;
}

.navbar-search {
  border: #0d0d0d solid 2px;
  border-top-color: #009de6;
}

.main-content.overlap {
  border-radius: 3px;
}

.nav-link {
  color: black !important;
}

.navbar-brand {
  color: #000 !important;
}

.sidebar .nav li.active > a {
  color: $warehouse-base-color !important;
}

$black-bg: purple !default;
$black-color: pink !default;
$btn-color: red !default;

@media print {
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @page {
    size: A4 landscape !important;
    max-height: 100%;
    max-width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .sidebar {
    display: none !important;
  }

  .main-content {
    margin: 0 !important;
  }

  .main-panel {
    width: 100% !important;

    .main-container {
      padding: 0 !important;
      background-color: transparent !important;
    }
  }

  .hierarchy-table {
    overflow: hidden;
  }

  .shadow-sm {
    box-shadow: none !important;
  }
}
